import { Component } from '@angular/core';
import { LoaderService } from './modules/auth/loader.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-loader',
    template: `
    <div *ngIf="isLoading$ | async" class="loader-overlay">
      <div class="loader"></div>
    </div>
  `,
    styles: [`
   .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}.loader {
    border: 8px solid rgba(255, 255, 255, 0.3); 
    border-top: 8px solid #345af6;
    border-radius: 50%;
    width: 50px; 
    height: 50px; 
    animation: spin 1s linear infinite; 
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  `]
})
export class LoaderComponent {
    isLoading$: Observable<boolean>;

    constructor(private loaderService: LoaderService) {
        this.isLoading$ = this.loaderService.loading$;
    }
}
