export class AuthModel {
  authToken: string;
  refreshToken: string;
  expiresIn: Date;

  setAuth(auth: AuthModel) {
    this.authToken = auth.authToken;
    this.refreshToken = auth.refreshToken;
    this.expiresIn = auth.expiresIn;
  }
}

export class AuthModelResponse {
  status: string;
  data: {
    accessToken: {
      response: {
        token: string,
        tokenExpirationInstant: any,
      }
    }
  };
}
